import React from "react";
import { heroBanner } from "../assets";
import { buttonClick } from "../animations";
import { motion } from "framer-motion";
import "../assets/css/main.css";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-8">
      <div className="flex flex-col">
        <h1 className="text-3xl md:text-4xl mb-4 font-bold text-headingColor text-center md:text-center w-full">
          <span className="text-primaryColor">
            Benvenuti da B&B Piazza d’Armi a Lamezia Terme
          </span>
        </h1>
        <video
  src={heroBanner} // Percorso del video
  alt="heroBanner"
  className="rounded-[30px] shadow-lg w-full h-[auto] md:h-[700px] md:object-cover"
  autoPlay
  muted
  playsInline // Permette la riproduzione inline su iPhone
  preload="auto" // Precarica il video
  style={{ pointerEvents: "none" }} // Rimuove interazioni dell'utente
/>
        <p className="text-gray-500 text-2xl mt-4 mb-4">
          Il luogo ideale per il tuo soggiorno a Lamezia Terme, nel cuore della
          Calabria. Siamo pronti ad accoglierti in un ambiente confortevole,
          elegante e a pochi passi dai principali punti di interesse della
          città.
        </p>
        <motion.button
          {...buttonClick}
          className="bg-headingColor text-white px-6 py-4 rounded-xl text-lg font-semibold shadow-md hover:shadow-lg hover:bg-primaryColor transition duration-300"
        >
          <Link
            to="/camere"
            className="w-full h-full flex text-3xl items-center justify-center"
          >
            Prenota ora una Camera
          </Link>
        </motion.button>
      </div>
    </div>
  );
};

export default HeroSection;
