import React, { useEffect } from "react";
import {
  Contact,
  Gallery,
  Header,
  Home,
  Room,
  RoomDetails,
  Footer,
  PrivacyPolicy,
  CookiePolicy,
  TermsConditions,
  MainLoader,
  NotFound,
  WhatsappButton,
  CookieBanner,
} from "../components";
import { useDispatch, useSelector } from "react-redux";
import { getAllRooms } from "../api";
import { setAllRooms } from "../context/actions/roomActions";
import { Routes, Route } from "react-router-dom";

import "../assets/css/main.css";

const Main = () => {
  const rooms = useSelector((state) => state.rooms.rooms);
  const dispatch = useDispatch();

  useEffect(() => {
    getAllRooms().then((data) => {
      dispatch(setAllRooms(data));
    });
  }, [dispatch]);

  if (!rooms || rooms.length === 0) {
    return (
      <div className="flex items-center justify-center h-screen">
        <MainLoader />
      </div>
    );
  }
  

  return (
    <div className="w-screen min-h-screen flex items-center justify-start bgbg flex-col">
      <Header />
      <div className="flex flex-col items-center justify-center py-28 px-6 md:px-24 2xl:px-96 backdrop-blur-custom w-full shadow-md">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/camere" element={<Room />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contatti" element={<Contact />} />
          <Route path="/room/:id" element={<RoomDetails />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/termini-e-condizioni" element={<TermsConditions />} />
        <Route path="*" element={<NotFound />} />

        </Routes>
      </div>
      <Footer />
      <WhatsappButton/>
      <CookieBanner/>
    </div>
  );
};

export default Main;
