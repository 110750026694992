import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getAllBookings } from '../api';
import { setBookings } from '../context/actions/bookingsAction';
import BookingData from './BookingData';

const DBBookings = () => {
  const [searchDate, setSearchDate] = useState('');
  const bookings = useSelector((state) => state.bookings);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!bookings) {
      getAllBookings().then(data => {
        dispatch(setBookings(data));
      });
    }
  }, [bookings, dispatch]);

  const filteredBookings = bookings ? bookings.filter(item => {
    return item.created.includes(searchDate);
  }) : [];

  return (
    <div className='flex flex-col items-center justify-start pt-6 gap-4'>
      <div className='w-full text-center bg-headingColor shadow-xl py-2 px-2 rounded-lg mb-2 border-b-2 border-textColor'>
        <h1 className='text-2xl font-medium text-primary'>Lista Prenotazioni</h1>
        <p className="text-sm text-primary">Visualizza, Modifica ed Elimina le Prenotazioni</p>
      </div>

      <div className="mb-4 w-full md:flex md:items-center md:justify-between">
        <input
          type="text"
          className="mt-1 p-4 block w-full border-gray-300 rounded-md"
          placeholder="Filtra per Data (gg/mm/aaaa)"
          value={searchDate}
          onChange={(e) => setSearchDate(e.target.value)}
        />
      </div>

      <div className="w-full grid gap-4">
        {filteredBookings.map((booking, index) => (
          <BookingData key={index} index={index} data={booking} admin={true} />
        ))}
      </div>
    </div>
  );
};

export default DBBookings;
