import React from 'react';
import { Link } from 'react-router-dom';
import { blueOriginFam } from '../assets';

const RelatedRooms = ({ currentRoomId, rooms }) => {
  // Filtra le stanze correlate escludendo quella attualmente visualizzata
  const relatedRooms = rooms.filter(room => room.roomId !== currentRoomId);

  return (
    <section className="my-8">
      <h3 className="text-2xl font-bold mb-4 px-3 text-headingColor">Stanze Correlate</h3>
      <div className="p-3 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
        {relatedRooms.map((room) => (
          <div key={room.roomId} className="relative group shadow-md">
            {/* Immagine della stanza */}
            <img
              src={room.images.length > 0 ? room.images[0] : blueOriginFam}
              alt={room.roomName}
              className="rounded-lg w-full h-48 object-cover transition-transform duration-300 group-hover:scale-105"
            />
            {/* Prezzo per notte */}
            <div className="absolute top-2 left-2 bg-pink-500 text-white text-sm font-semibold px-3 py-1 rounded-lg shadow-lg">
              {`€${room.pricePerNight} / notte`}
            </div>
            {/* Nome della stanza */}
            <div className="absolute bottom-2 left-2 text-white font-bold text-lg bg-black bg-opacity-50 px-2 py-1 rounded-lg">
              {room.roomName}
            </div>
            {/* Numero di ospiti */}
            <p className="absolute bottom-2 right-2 text-white text-sm bg-black bg-opacity-50 px-2 py-1 rounded-lg">
              {`Ospiti: ${room.maxGuests}`}
            </p>
            {/* Link per visualizzare i dettagli della stanza */}
            <Link
              to={`/room/${room.roomId}`}
              className="absolute inset-0"
              aria-label={`Dettagli della stanza ${room.roomName}`}
            ></Link>
          </div>
        ))}
      </div>
    </section>
  );
};

export default RelatedRooms;
