// Imposta tutte le stanze
export const setAllRooms = (rooms) => {
    return {
        type: "SET_ALL_ROOMS",
        rooms: rooms,
    };
};

// Richiedi tutte le stanze
export const getAllRooms = () => {
    return {
        type: "GET_ALL_ROOMS",
    };
};

// Aggiungi una stanza
export const addRoom = (room) => {
    return {
        type: "ADD_ROOM",
        room: room,
    };
};

// Elimina una stanza
export const removeRoom = (roomId) => {
    return {
        type: "REMOVE_ROOM",
        roomId: roomId,
    };
};
