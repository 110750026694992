import React from "react";
import { DBHeader, DBBottomSection } from "../components";

const Dashboard = () => {
  return (
    <div className="w-full bg-bgDashboard min-h-screen">
      <DBHeader />
      <DBBottomSection />
    </div>
  );
};

export default Dashboard;