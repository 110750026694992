import React, { useState } from "react";
import { sendContactMessage } from "../api";
import { Link } from "react-router-dom"; // Importa la funzione sendContactMessage

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [privacy, setPrivacy] = useState(false); // Stato per la checkbox Privacy
  const [marketing, setMarketing] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false); // Stato per il loading
  const [error, setError] = useState(""); // Stato per eventuali errori
  const [success, setSuccess] = useState(""); // Stato per la conferma di successo
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    privacy: "",
  });

  // Funzione per la validazione del form
  const validateForm = () => {
    const errors = {};
    let isValid = true;

    // Validazione per il nome
    if (!name.trim()) {
      errors.name = "Il nome è obbligatorio";
      isValid = false;
    }

    // Validazione per l'email
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!email.trim()) {
      errors.email = "L'email è obbligatoria";
      isValid = false;
    } else if (!emailPattern.test(email)) {
      errors.email = "Formato email non valido";
      isValid = false;
    }

    // Validazione per il telefono
    const phonePattern = /^[0-9]{10}$/;
    if (!phone.trim()) {
      errors.phone = "Il numero di telefono è obbligatorio";
      isValid = false;
    } else if (!phonePattern.test(phone)) {
      errors.phone = "Numero di telefono non valido";
      isValid = false;
    }

    // Validazione per il messaggio
    if (!message.trim()) {
      errors.message = "Il messaggio è obbligatorio";
      isValid = false;
    } else if (message.length < 10) {
      errors.message = "Il messaggio deve contenere almeno 10 caratteri";
      isValid = false;
    }

    if (!privacy) {
      errors.privacy = "Devi accettare la normativa sulla privacy";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prima valida il form
    if (!validateForm()) {
      return; // Se il form non è valido, non inviare il messaggio
    }

    setLoading(true); // Imposta lo stato di caricamento a true
    setError(""); // Resetta eventuali errori
    setSuccess(""); // Resetta il messaggio di successo

    try {
      // Esegui la chiamata API
      const response = await sendContactMessage(name, email, phone, message);

      // Se la chiamata API ha avuto successo
      if (response.success) {
        setSuccess(
          "Messaggio inviato con successo! Ti Ricontatteremo al piu presto, Grazie."
        );
        // Invia il messaggio tramite Redux, se necessario
      }
    } catch (err) {
      // Gestisci eventuali errori
      setError("Errore nell'invio del messaggio. Riprova più tardi.");
    } finally {
      setLoading(false); // Imposta lo stato di caricamento a false
    }

    // Pulisce il modulo dopo l'invio
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
    setPrivacy(false);
    setMarketing(false);
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-textColor text-3xl font-semibold text-center mb-6">
        Vuoi contattarci per ulteriori chiarimenti o informazioni?
      </h2>
      <h4 className="text-xl text-center mb-6">Compila il Form di Contatto</h4>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="name"
          >
            Nome:
          </label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md"
            required
          />
          {formErrors.name && (
            <div className="text-red-500 text-sm">{formErrors.name}</div>
          )}
        </div>

        <div>
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="email"
          >
            Email:
          </label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md"
            required
          />
          {formErrors.email && (
            <div className="text-red-500 text-sm">{formErrors.email}</div>
          )}
        </div>

        <div>
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="phone"
          >
            Telefono:
          </label>
          <input
            id="phone"
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md"
            required
          />
          {formErrors.phone && (
            <div className="text-red-500 text-sm">{formErrors.phone}</div>
          )}
        </div>

        <div>
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="message"
          >
            Messaggio:
          </label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md"
            required
          />
          {formErrors.message && (
            <div className="text-red-500 text-sm">{formErrors.message}</div>
          )}
        </div>

        <div>
          <label className="flex items-center">
            <input
              id="checked-checkbox"
              type="checkbox"
              checked={privacy}
              onChange={(e) => setPrivacy(e.target.checked)}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:bg-gray-700 dark:border-gray-600 mr-1"
            />
            <span>
              Acconsento al
              <Link
                to="/privacy-policy"
                className="ml-1 text-blue-600 text-semibold"
              >
                Trattamento dei dati
              </Link>
              {" "}per finalità di marketing
            </span>
          </label>
          {formErrors.privacy && (
            <div className="text-red-500 text-sm">{formErrors.privacy}</div>
          )}
        </div>

        <div></div>

        <div>
          <label>
            <input
              id="checked-checkbox"
              type="checkbox"
              checked={marketing}
              onChange={(e) => setMarketing(e.target.checked)}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:bg-gray-700 dark:border-gray-600 mr-1"
            />
            <span>Acconsento a ricevere comunicazioni di</span>
            <Link
              to="/privacy-policy"
              className="ml-1 text-blue-600 text-semibold"
            >
              Marketing
            </Link>
          </label>
        </div>

        {error && <div className="text-red-500">{error}</div>}
        {success && <div className="text-green-500">{success}</div>}

        <button
          type="submit"
          className="w-full py-2 px-4 bg-textColor text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
          disabled={loading}
        >
          {loading ? "Invio in corso..." : "Invia Messaggio"}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
