import React, { useEffect, useState } from "react";
import { HiCurrencyEuro } from "../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { deleteRoom, getAllRooms } from "../api";
import { setAllRooms } from "../context/actions/roomActions";
import { alertNull, alertSuccess } from "../context/actions/alertAction";
import EditRoomForm from "./EditRoomForm";
import { MainLoader } from "../components/";

const DBRooms = () => {
  const rooms = useSelector((state) => state.rooms.rooms || []);
  const dispatch = useDispatch();
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openRooms, setOpenRooms] = useState({});

  useEffect(() => {
    setLoading(true);
    getAllRooms()
      .then((data) => {
        dispatch(setAllRooms(data));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Errore nel caricamento delle stanze:", error);
        setLoading(false);
      });
  }, [dispatch]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <MainLoader />
      </div>
    );
  }

  if (rooms.length === 0) {
    return <div>Nessuna stanza disponibile.</div>;
  }

  const handleEdit = (room) => {
    setSelectedRoom(room);
    setIsEditing(true);
    // Dispatch the updated room directly to the Redux store after editing
    dispatch(
      setAllRooms(rooms.map((r) => (r.roomId === room.roomId ? room : r)))
    );
  };

  const handleDelete = (roomId) => {
    if (window.confirm("Sei sicuro di voler eliminare la stanza?")) {
      deleteRoom(roomId).then((res) => {
        dispatch(alertSuccess("Stanza Eliminata"));
        setTimeout(() => {
          dispatch(alertNull());
        }, 3000);
        // Optimistically remove the room from the state
        const updatedRooms = rooms.filter((room) => room.roomId !== roomId);
        dispatch(setAllRooms(updatedRooms));
      });
    }
  };

  const closeEditModal = () => {
    setIsEditing(false);
    setSelectedRoom(null);
  };

  const toggleRoom = (roomId) => {
    setOpenRooms((prevState) => ({
      ...prevState,
      [roomId]: !prevState[roomId],
    }));
  };

  return (
    <div className="w-full pt-6">
      <div className="px-2 w-full text-center bg-headingColor shadow-xl py-2 rounded-lg mb-2 border-b-2 border-textColor">
        <h1 className="text-2xl font-medium text-primary">Lista Stanze</h1>
        <p className="text-sm text-primary">
          Visualizza, Modifica ed Elimina le Stanze
        </p>
      </div>

      <div
        id="accordion-collapse"
        data-accordion="collapse"
        className="space-y-4"
      >
        {rooms.map((room) => (
          <div key={room.roomId}>
            <h2 id={`accordion-collapse-heading-${room.roomId}`}>
              <button
                type="button"
                className="flex items-center justify-between w-full p-5 font-medium bg-white text-black border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 hover:bg-gray-100 gap-3"
                data-accordion-target={`#accordion-collapse-body-${room.roomId}`}
                aria-expanded={openRooms[room.roomId] ? "true" : "false"}
                aria-controls={`accordion-collapse-body-${room.roomId}`}
                onClick={() => toggleRoom(room.roomId)}
              >
                <div>
                  <span>{room.roomName}</span>
                  <span className="flex items-center">
                    <HiCurrencyEuro className="text-orange-400 text-2xl" />
                    <span className="font-medium ml-1">
                      {parseFloat(room.pricePerGuest[0]?.price || 0).toFixed(2)}
                      € | Per 1 Ospite
                    </span>
                  </span>
                </div>

                <div className="px-6 py-2 flex justify-between">
                  <svg
                    data-accordion-icon
                    className={`w-4 h-4 ${
                      openRooms[room.roomId] ? "rotate-180" : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5 5 1 1 5"
                    />
                  </svg>
                </div>
              </button>
            </h2>
            <div
              id={`accordion-collapse-body-${room.roomId}`}
              className={openRooms[room.roomId] ? "" : "hidden"}
              aria-labelledby={`accordion-collapse-heading-${room.roomId}`}
            >
              <div className="bg-white p-5 border border-b-0 border-gray-200">
                {room.images && room.images.length > 0 ? (
                  <img
                    src={room.images[0]} // Prima immagine dell'array images
                    alt={`${room.roomName}`}
                    className="w-full h-44 object-cover mb-4 rounded-lg"
                  />
                ) : (
                  <div className="w-full h-32 flex items-center justify-center border border-gray-300 mb-4">
                    <span>No foto</span>
                  </div>
                )}
                <p className="text-xl font-bold mb-2">Caratteristiche:</p>
                <div className="flex flex-wrap mb-4">
                  <span className="bg-blue-100 text-blue-800 text-l font-semibold mr-2 px-2.5 py-0.5 rounded-lg mb-2">
                    {room.roomType}
                  </span>
                  <span className="bg-green-100 text-green-800 text-l font-semibold mr-2 px-2.5 py-0.5 rounded-lg">
                    {`Max ${room.maxGuests} Ospiti`}
                  </span>
                </div>
                <div className="bg-yellow-100 text-yellow-800 text-l font-semibold mr-2 px-2.5 py-0.5 rounded-lg mb-4">
                  {room.pricePerGuest.map((guestPrice, index) => {
                    // Convertiamo price in numero (se è una stringa, la convertiamo in numero)
                    const validPrice = parseFloat(guestPrice.price);

                    // Verifica che il prezzo sia un numero valido e che il numero di ospiti sia valido
                    if (isNaN(validPrice) || !guestPrice.guests) {
                      return null; // Se il prezzo non è valido o il numero di ospiti non è definito, non renderizzare nulla
                    }

                    return (
                      <span key={index} className="block">
                        {`Prezzo per ${
                          guestPrice.guests
                        } ospite/i: €${validPrice.toFixed(2)}`}
                      </span>
                    );
                  })}
                </div>

                <p className="mb-4 text-xl">
                  <strong>Descrizione:</strong>{" "}
                  <span className="text-lg">{room.description}</span>
                </p>
                <div className="mb-4">
                  <strong>Servizi:</strong>
                  <ul className="flex flex-wrap">
                    {room.amenities.map((amenity, index) => (
                      <li key={index} className="mr-2">
                        <span className="bg-purple-100 text-purple-800 text-xs font-semibold px-2.5 py-0.5 rounded-lg">
                          {amenity}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
                <p className="mb-4">
                  <strong>Disponibilità:</strong>
                  {new Date(
                    room.availability.startDate
                  ).toLocaleDateString()} -{" "}
                  {new Date(room.availability.endDate).toLocaleDateString()}
                </p>
                <div>
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded-md mt-2 mr-2 "
                    onClick={() => handleEdit(room)}
                  >
                    Modifica
                  </button>
                  <button
                    className="bg-red-500 text-white px-4 py-2 rounded-md  "
                    onClick={() => handleDelete(room.roomId)}
                  >
                    Elimina
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {isEditing && selectedRoom && (
        <EditRoomForm room={selectedRoom} onClose={closeEditModal} />
      )}
    </div>
  );
};

export default DBRooms;
