import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Avatar, Logo } from "../assets";
import { isActiveStyles, isNotActiveStyles } from "../utils/styles";
import { motion } from "framer-motion";
import { slideTop } from "../animations";
import { FaUserCircle, MdLogout } from "../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { getAuth } from "firebase/auth";
import { app } from "../config/firebase.config";
import { setUserNull } from "../context/actions/userActions";

const DBHeader = () => {
  const user = useSelector((state) => state.user);
  const [isMenu, setIsMenu] = useState(false);
  const firebaseAuth = getAuth(app);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const signOut = () => {
    firebaseAuth
      .signOut()
      .then(() => {
        dispatch(setUserNull());
        navigate("/login", { replace: true });
      })
      .catch((err) => console.log(err));
  };

  const menuLinks = [
    { path: "/dashboard/home", label: "Dashboard | Home" },
    { path: "/dashboard/bookings", label: "Prenotazioni" },
    { path: "/dashboard/create-bookings", label: "Inserisci Prenotazione" },
    { path: "/dashboard/rooms", label: "Stanze" },
    { path: "/dashboard/create-room", label: "Crea Stanza" },
    { path: "/dashboard/contacts", label: "Messaggi da Form" },
    { path: "/dashboard/reviews", label: "Recensioni" },
    { path: "/dashboard/users", label: "Utenti" },
  ];

  return (
    <motion.div className="bg-primary w-full shadow-md mb-16">
      <header className="bg-primary w-full shadow-md flex justify-between py-2 px-6 rounded-b-2xl fixed z-20">
        <NavLink to={"/"} className="flex items-center gap-1">
          <img src={Logo} alt="logo" className="w-14" />
          <div className="text-center bg-textColor block px-2 py-2 text-primary rounded-lg">
            <h1 className="text-xs">Torna Alla Home</h1>
          </div>
        </NavLink>

        <motion.div className="flex items-center gap-4">
         
          <div className="relative">
            {user ? (
              <div
                className="w-8 h-8 rounded-full cursor-pointer overflow-hidden flex items-center justify-center"
                onMouseEnter={() => setIsMenu(true)}
                onClick={() => setIsMenu(!isMenu)}
              >
                <motion.img
                  className="w-full h-full object-cover"
                  src={user?.picture || Avatar}
                  alt="avatar"
                  whileHover={{ scale: 1.15 }}
                  referrerPolicy="no-referrer"
                />
              </div>
            ) : (
              <NavLink to={"/login"}>
                <FaUserCircle className="text-2xl text-textColor cursor-pointer" />
              </NavLink>
            )}

            {isMenu && (
              <motion.div
                {...slideTop}
                onMouseLeave={() => setIsMenu(false)}
                className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-md py-2 z-50"
              >
                <div className="flex flex-col items-center gap-4">
                  {menuLinks.map(({ path, label }) => (
                    <NavLink
                      key={path}
                      to={path}
                      className={({ isActive }) =>
                        isActive
                          ? `${isActiveStyles} block w-full text-left px-4 py-2`
                          : `${isNotActiveStyles} block w-full text-left px-4 py-2`
                      }
                    >
                      {label}
                    </NavLink>
                  ))}
                </div>
                <button
                  onClick={signOut}
                  className="block w-full text-left px-4 py-2 rounded-md mt-2 text-textColor hover:bg-headingColor hover:text-white"
                >
                  <MdLogout className="text-xm inline mr-2" />
                  Disconnetti
                </button>
              </motion.div>
            )}
          </div>
        </motion.div>
      </header>
    </motion.div>
  );
};

export default DBHeader;
