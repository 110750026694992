import axios from "axios";

// export const baseURL = "http://localhost:5001/fullstack-beb-template/us-central1/app";
export const baseURL =
  "https://app-olrhdboizq-uc.a.run.app";

// Aggiungere una nuova stanza

// -------------------- API CALLS --------------------

export const validateUserJWTToken = async (token) => {
  try {
    const res = await axios.get(`${baseURL}/api/users/jwtVerification`, {
      headers: { Authorization: "Bearer " + token },
    });
    return res.data.data;
  } catch (err) {
    return null;
  }
};

// prendi tutti gli utenti
export const getAllUsers = async () => {
  try {
    const res = await axios.get(`${baseURL}/api/users/all`);
    return res.data.data;
  } catch (err) {
    return null;
  }
};

// Add an Item
export const addNewRoom = async (data) => {
  try {
    const res = await axios.post(`${baseURL}/api/rooms/create`, { ...data });
    return res.data.data;
  } catch (err) {
    return null;
  }
};

// Prendere tutte le stanze
export const getAllRooms = async () => {
  try {
    const res = await axios.get(`${baseURL}/api/rooms/all`);
    // Verifica che i dati esistano e siano un array, altrimenti restituisci un array vuoto
    if (res.data && Array.isArray(res.data.data)) {
      return res.data.data;
    } else {
      return []; // Se non ci sono dati, restituisci un array vuoto
    }
  } catch (err) {
    console.error("Errore durante il caricamento delle stanze", err);
    return []; // Restituisci un array vuoto in caso di errore
  }
};

export const editRoom = async (roomId, updatedRoomData) => {
  const response = await fetch(`${baseURL}/api/rooms/edit/${roomId}`, {
    method: 'PUT', // or 'PATCH' depending on the API design
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updatedRoomData),
  });

  if (!response.ok) {
    throw new Error("Failed to edit room");
  }

  return response.json();
};

export const updateRoom = async (room) => {
  const response = await fetch(`${baseURL}/api/rooms/edit/${room.roomId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(room),
  });

  if (!response.ok) {
    const errorData = await response.json();
    console.error("Error details:", errorData);
    throw new Error("Failed to update room");
  }

  return response.json();
};

// Eliminare una stanza
export const deleteRoom = async (roomId) => {
  try {
    const res = await axios.delete(`${baseURL}/api/rooms/delete/${roomId}`);
    return res.data.data;
  } catch (err) {
    return null;
  }
};

// Recupera tutti gli ordini per il DB
export const getAllBookings = async () => {
  try {
      const res = await axios.get(
          `${baseURL}/api/rooms/bookings`
          );
      return res.data.data;
  } catch (err) {
      return null;
  }
};

export const getBookingsByRoom = async (roomId) => {
  try {
    // Usa roomId per filtrare le prenotazioni
    const res = await axios.get(`${baseURL}/api/rooms/bookings/${roomId}`);
    
    // Restituisci i dati delle prenotazioni
    return res.data.data;
  } catch (err) {
    return null;
  }
};

// Recupera tutti gli ordini per il DB
export const updateBookingSts = async (bookingId, sts) => {
  try {
      const res = await axios.post(
          `${baseURL}/api/rooms/updatebookings/${bookingId}`,
          null,
          {params: { sts: sts }}
      );
      return res.data.data;
  } catch (err) {
      return null;
  }
};

// FORM DI CONTATTO
export const sendContactMessage = async (name, email, phone, message) => {
  try {
    const response = await axios.post(`${baseURL}/api/contacts/send`, {
      name,
      email,
      phone,
      message,
    });

    if (response.data.success) {
      return { success: true, id: response.data.id };
    } else {
      throw new Error('Errore nel salvataggio del messaggio');
    }
  } catch (error) {
    console.error('Errore nella chiamata API:', error);
    throw new Error('Errore nell\'invio del messaggio');
  }
};


export const createBooking = async (data) => {
  try {
    const response = await axios.post(`${baseURL}/api/rooms/create-booking`, {
      data,
    });

    if (response.data.success) {
      return { success: true, bookingId: response.data.bookingId };
    } else {
      throw new Error('Errore nel salvataggio della prenotazione');
    }
  } catch (error) {
    console.error('Errore nella chiamata API:', error);
    throw new Error('Errore nell\'invio della prenotazione');
  }
};

export const getAllContacts = async () => {
  try {
    const res = await axios.get(`${baseURL}/api/contacts/all`);
    // Correzione: accediamo direttamente alla chiave `contacts`
    return res.data.contacts;
  } catch (err) {
    console.error("Errore nella chiamata API:", err); // Logga l'errore per diagnosticare meglio
    return null; // Restituisce null se si verifica un errore
  }
};


export const deleteContact = async (id) => {
  try {
    // Chiamata DELETE per eliminare il messaggio con l'id specificato
    const res = await axios.delete(`${baseURL}/api/contacts/delete/${id}`);
    if (res.data.success) {
      console.log("Messaggio eliminato con successo!");
      return true; // Restituisci true se la richiesta è andata a buon fine
    }
  } catch (error) {
    console.error("Errore nell'eliminazione del messaggio:", error);
    return false; // Restituisci false in caso di errore
  }
};

// FINE FORM DI CONTATTO

// Funzione per inviare una nuova recensione
export const sendReview = async (name, rating, message) => {
  try {
    const response = await fetch(`${baseURL}/api/reviews/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, rating, message }),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.error || "Errore nell'invio della recensione");
    }

    return data;
  } catch (error) {
    console.error("Errore:", error);
    throw error;
  }
};

// Funzione per ottenere tutte le recensioni
export const getAllReviews = async () => {
  try {
    const response = await fetch(`${baseURL}/api/reviews/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.error || "Errore nel recupero delle recensioni");
    }

    return data.reviews;
  } catch (error) {
    console.error("Errore:", error);
    throw error;
  }
};

// Funzione per eliminare una recensione tramite ID
export const deleteReview = async (id) => {
  try {
    const response = await fetch(`${baseURL}/api/reviews/delete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.error || "Errore nell'eliminazione della recensione");
    }

    return data;
  } catch (error) {
    console.error("Errore:", error);
    throw error;
  }
};