import React from "react";
import {
  GallerySection,
  GalleryStruttura,
  Faq,
  RoomAvailable,
  HeroSection,
  ContactForm,
  ReviewsSlider,
  WhyChooseUs,
  ParnerSection,
  MetaDescription,
} from "../components";
import { motion } from "framer-motion";
import "../assets/css/main.css";

const Home = () => {
  return (
    <motion.div className="max-w-7xl grid grid-cols-1 md:grid-cols-1 gap-4">
      <MetaDescription
        title="B&B Piazza d'Armi | Prenota una Camera a Lamezia Terme"
        description="Benvenuti al B&B Piazza d'Armi, il tuo soggiorno ideale a Lamezia Terme. Camere accoglienti, colazione inclusa e vicinanza ai principali punti d'interesse."
        image="./public/images/fronte-piatto.jpg"
        url="https://www.bebpiazzadarmi.it/camere"
      />
      <div className="mb-16">
        <HeroSection />
      </div>
      <div className="mb-16">
        <RoomAvailable />
      </div>
      <div className="mb-16">
        <GalleryStruttura />
      </div>
      <div className="mb-16">
        <WhyChooseUs />
      </div>
      <div className="mb-16">
        <ParnerSection />
      </div>
      <div className="mb-16 flex ">
        <ReviewsSlider />
      </div>
      <div className="mb-16">
        <GallerySection />
      </div>
      <div className="mb-16">
        <Faq />
      </div>
      <div className="mb-16">
        <ContactForm />
      </div>
    </motion.div>
  );
};

export default Home;
