import React, { useState } from "react";
import {domandeFaq} from "../utils/domandeFaq";

const Faq = () => {
  const [openAccordion, setOpenAccordion] = useState(null);

  // Gestisce l'apertura e chiusura degli accordion
  const handleAccordionClick = (id) => {
    setOpenAccordion((prevOpen) => (prevOpen === id ? null : id));
  };

  return (
    <div id="domandeFaq">
      <h2 className="text-3xl text-center mb-4 font-bold text-textColor">
        Domande Frequenti
      </h2>
      <p className="font-semibold text-xl text-center mb-8 text-gray-600">
        La nostra sezione delle Domande Frequenti è stata progettata per
        rispondere alle vostre curiosità principali e offrire una panoramica
        chiara e dettagliata delle informazioni utili. Fate clic sulle domande
        per svelare le risposte!
      </p>

      {domandeFaq.map((item) => (
        <div key={item.id} className="rounded-lg">
          <h2 id={`accordion-heading-${item.id}`}>
            <button
              type="button"
              className=" mb-3 rounded-lg flex items-center justify-between w-full p-5 font-medium border text-headingColor border-gray-200 bg-white focus:ring-2 focus:ring-gray-200 hover:bg-headingColor hover:text-white  transition duration-300 ease-in-out"
              onClick={() => handleAccordionClick(item.id)}
              aria-expanded={openAccordion === item.id}
              aria-controls={`accordion-body-${item.id}`}
            >
              <span>{item.question}</span>
              <svg
                className={`w-4 h-4 transform ${
                  openAccordion === item.id ? "rotate-180" : ""
                }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
          </h2>
          <div
            id={`accordion-body-${item.id}`}
            className={`${openAccordion === item.id ? "block" : "hidden"} -mt-3 mb-2 rounded-lg p-5 border border-t-0 bg-white text-headingColor`}
            aria-labelledby={`accordion-heading-${item.id}`}
          >
            <p className=" text-gray-500">{item.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Faq;
