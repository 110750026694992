import React from 'react';
import { Helmet } from 'react-helmet';

const MetaDescription = ({ title, description, image, url }) => (
  <Helmet>
    {/* Title e Meta Description */}
    <title>{title}</title>
    <meta name="description" content={description} />

    {/* OpenGraph */}
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    <meta property="og:url" content={url} />
    <meta property="og:type" content="website" />
  </Helmet>
);

export default MetaDescription;
