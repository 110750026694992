import React from 'react';
import {ThemeProvider, createTheme} from "@mui/material";
import MaterialTable from "material-table";

const DataTable = ({columns, data, title, actions}) => {

    // Verifica se la prop 'data' è null o undefined
    if (!data) {
        return null; // Non renderizzare nulla se 'data' non è definito
    }

    const defaultMaterialTheme = createTheme(); 

    return (
        
        <ThemeProvider theme={defaultMaterialTheme}>    
            <MaterialTable
                columns={columns}
                data={data}
                title={title}
                actions={actions}
                localization={{
                    header: {
                        actions: 'Azione'
                    },
                    toolbar: {
                        searchPlaceholder: 'Cerca',
                        
                    },
                    pagination: {
                        nextAriaLabel: 'ciao',
                        labelRowsPerPage: "Righe Per Pagina",
                        labelRowsSelect: "Righe"
                    }
                }}
            />
                
        </ThemeProvider>
       
    )
}

export default DataTable