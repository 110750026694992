import React, { useState, useRef, useCallback } from "react";
import "react-image-lightbox/style.css"; // Importa gli stili del lightbox
import Lightbox from "react-image-lightbox";
import {
  fronteAngolo,
  fronteGrandangolo,
  frontePiatto,
  corridoio,
  cucina,
  pianerottolo,
  poltrone,
  salaColazione,
  salaColazione2,
  salaColazione3,
  salaColazione4,
  salaColazione5,
  scale,
  scale2,
} from "../assets/index";

const GalleryStruttura = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxImageIndex, setLightboxImageIndex] = useState(0);
  const imageRefs = useRef([]);
  const containerRef = useRef(null);
  const isAnimating = useRef(false); // Variabile per controllare se è in corso un'animazione

  const images = [
    { 
      url: frontePiatto, 
      alt: "Vista frontale del B&B a Lamezia Terme", 
      title: "Vista Frontale del B&B" 
    },
    { 
      url: fronteAngolo, 
      alt: "Vista frontale angolare del B&B a Lamezia Terme", 
      title: "Vista Frontale Angolare del B&B" 
    },
    { 
      url: fronteGrandangolo, 
      alt: "Vista grandangolare della facciata del B&B", 
      title: "Vista Grandangolare della Facciata" 
    },
    { 
      url: cucina, 
      alt: "Cucina attrezzata del B&B", 
      title: "Cucina del B&B" 
    },
    { 
      url: pianerottolo, 
      alt: "Pianerottolo elegante del B&B", 
      title: "Pianerottolo del B&B" 
    },
    { 
      url: poltrone, 
      alt: "Area relax con poltrone confortevoli", 
      title: "Area Relax del B&B" 
    },
    { 
      url: salaColazione, 
      alt: "Sala colazione spaziosa con tavoli", 
      title: "Sala Colazione del B&B" 
    },
    { 
      url: salaColazione2, 
      alt: "Dettaglio della sala colazione con tavoli", 
      title: "Dettaglio Sala Colazione" 
    },
    { 
      url: salaColazione3, 
      alt: "Vista panoramica della sala colazione del B&B con tavoli", 
      title: "Vista Panoramica Sala Colazione" 
    },
    { 
      url: salaColazione4, 
      alt: "Sala colazione ordinata con tavoli", 
      title: "Sala Colazione Ordinata" 
    },
    { 
      url: salaColazione5, 
      alt: "Angolo della sala colazione con tavoli", 
      title: "Angolo Sala Colazione" 
    },
    { 
      url: scale, 
      alt: "Scalinata interna del B&B", 
      title: "Scalinata Interna" 
    },
    { 
      url: scale2, 
      alt: "Dettaglio della scalinata con decorazioni eleganti", 
      title: "Dettaglio della Scalinata" 
    },
    { 
      url: corridoio, 
      alt: "Corridoio luminoso del B&B a Lamezia Terme", 
      title: "Corridoio del B&B" 
    },
  ];

  const handlePrevClick = useCallback(() => {
    if (isAnimating.current) return; // Evita di eseguire la funzione se è in corso un'animazione
    isAnimating.current = true; // Imposta l'animazione come in corso

    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex > 0 ? prevIndex - 1 : images.length - 1;
      containerRef.current.scrollBy({
        left: -imageRefs.current[0].offsetWidth,
        behavior: "smooth",
      });
      return newIndex;
    });

    setTimeout(() => {
      isAnimating.current = false; // Reset l'animazione dopo un timeout
    }, 500); // Tempo sufficiente per completare l'animazione
  }, [images.length]);

  const handleNextClick = useCallback(() => {
    if (isAnimating.current) return; // Evita di eseguire la funzione se è in corso un'animazione
    isAnimating.current = true; // Imposta l'animazione come in corso

    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex < images.length - 1 ? prevIndex + 1 : 0;
      containerRef.current.scrollBy({
        left: imageRefs.current[0].offsetWidth,
        behavior: "smooth",
      });
      return newIndex;
    });

    setTimeout(() => {
      isAnimating.current = false; // Reset l'animazione dopo un timeout
    }, 500); // Tempo sufficiente per completare l'animazione
  }, [images.length]);

  const openLightbox = (index) => {
    setLightboxImageIndex(index);
    setIsLightboxOpen(true);
  };

  return (
    <div className="w-full flex flex-col items-start">
      <div className="flex flex-col items-start justify-start gap-1">
        <p className="text-3xl text-headingColor font-bold">
          La Nostra Struttura
        </p>
        <p className="mb-2 text-xl">
          Esplora il nostro B&B attraverso la galleria fotografica. Dalle camere
          accoglienti agli spazi comuni curati nei dettagli, lasciati ispirare
          dalla nostra ospitalità e dall’atmosfera unica di Lamezia Terme.
        </p>
      </div>
      <div className="relative w-full overflow-hidden mb-15">
        {currentIndex > 0 && (
          <div className="absolute top-1/2 left-4 transform -translate-y-1/2 z-20 hidden md:block">
            <button
              onClick={handlePrevClick}
              className="bg-white rounded-full w-12 h-12 flex items-center justify-center shadow-lg transition duration-300 ease-in-out transform hover:bg-gray-200 hover:scale-105"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-8 h-8"
              >
                <path d="M15 18l-6-6 6-6v12z" />
              </svg>
            </button>
          </div>
        )}
        <div
          ref={containerRef}
          className="relative w-full flex overflow-x-auto scrollbar-none py-8"
        >
          <div className="flex space-x-6">
            {images.map((image, i) => (
              <SliderCard
                key={i}
                image={image}
                index={i}
                onClick={() => openLightbox(i)}
                ref={(el) => (imageRefs.current[i] = el)}
              />
            ))}
          </div>
        </div>
        {currentIndex < images.length - 1 && (
          <div className="absolute top-1/2 right-4 transform -translate-y-1/2 z-20 hidden md:block">
            <button
              onClick={handleNextClick}
              className="bg-white rounded-full w-12 h-12 flex items-center justify-center shadow-lg transition duration-300 ease-in-out transform hover:bg-gray-200 hover:scale-105"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-8 h-8"
              >
                <path d="M9 18l6-6-6-6v12z" />
              </svg>
            </button>
          </div>
        )}
      </div>

      {/* Lightbox */}
      {isLightboxOpen && (
        <Lightbox
          mainSrc={images[lightboxImageIndex].url}
          nextSrc={images[(lightboxImageIndex + 1) % images.length].url}
          prevSrc={
            images[(lightboxImageIndex + images.length - 1) % images.length].url
          }
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setLightboxImageIndex(
              (lightboxImageIndex + images.length - 1) % images.length
            )
          }
          onMoveNextRequest={() =>
            setLightboxImageIndex((lightboxImageIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

const SliderCard = React.forwardRef(({ image, index, onClick }, ref) => {
  return (
    <div
      className="w-64 cursor-pointer rounded-xl bg-headingColor shadow-md flex flex-col items-center justify-center gap-4"
      onClick={onClick}
      ref={ref}
    >
      <img
        src={image.url}
        alt={image.alt}
        loading="lazy"
        className="w-full h-48 object-cover rounded-md"
      />
    </div>
  );
});

export default GalleryStruttura;
