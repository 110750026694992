import React from "react";
import { Link } from "react-router-dom";
import { blueOriginFam } from "../assets";

const RoomCard = ({ room }) => {
  const { roomName, pricePerNight, images, maxGuests, roomId } = room;

  return (
    <div className="relative group shadow-lg">
      <img
        src={images.length > 0 ? images[0] : blueOriginFam}
        alt={roomName}
        className="rounded-lg w-full h-48 object-cover transition-transform duration-300 group-hover:scale-105"
      />
      <div className="absolute top-2 left-2 bg-bgPrice text-white text-sm font-semibold px-3 py-1 rounded-lg shadow-lg">
        <span className="flex items-center">
          <span className="font-medium ml-1">
            A Partire da {parseFloat(room.pricePerGuest[0]?.price || 0)}€ / Notte
          </span>
        </span>
      </div>
      <div className="absolute bottom-2 left-2 text-white font-bold text-lg bg-black bg-opacity-50 px-2 py-1 rounded-lg">
        {roomName}
      </div>
      <p className="absolute bottom-2 right-2 text-white text-sm bg-black bg-opacity-50 px-2 py-1 rounded-lg">
        {`Ospiti: ${maxGuests}`}
      </p>
      <Link
        to={`/room/${roomId}`}
        className="absolute inset-0"
        aria-label={`Dettagli della stanza ${roomName}`}
      ></Link>
    </div>
  );
};

export default RoomCard;
