import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllReviews, deleteReview } from "../api";
import { setAllReviews, removeReviews } from "../context/actions/reviewAction";

const DBReviews = () => {
  const dispatch = useDispatch();
  const reviews = useSelector((state) => state.reviews.reviews || []);
  const [confirmationId, setConfirmationId] = useState(null);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const data = await getAllReviews();
        dispatch(setAllReviews(data));
      } catch (error) {
        console.error("Errore nel recupero delle recensioni:", error);
      }
    };
    fetchReviews();
  }, [dispatch]);

  const handleDelete = async (id) => {
    try {
      await deleteReview(id);
      dispatch(removeReviews(id));
      setConfirmationId(null); // Reset confirmation state after deletion
    } catch (error) {
      console.error("Errore nell'eliminazione della recensione:", error);
    }
  };

  const handleConfirmDelete = (id) => {
    setConfirmationId(id); // Set review ID to confirm deletion
  };

  const handleCancelDelete = () => {
    setConfirmationId(null); // Cancel the deletion
  };

  const renderStars = (rating) => {
    return Array.from({ length: 5 }, (_, index) => (
      <span
        key={index}
        className={`text-xl ${index < rating ? "text-yellow-500" : "text-gray-300"}`}
      >
        ★
      </span>
    ));
  };

  return (
    <div className="p-6 shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-center">Gestione Recensioni</h2>
      {reviews.map((review) => (
        <div
          key={review.id}
          className="mb-6 p-4 border rounded-lg shadow-sm bg-gray-50"
        >
          <div className="flex items-center justify-between">
            <h3 className="font-semibold text-lg">{review.name}</h3>
            <p className="text-gray-500">{renderStars(review.rating)}</p>
          </div>
          <p className="text-gray-700 mt-2">{review.message}</p>

          {confirmationId === review.id ? (
            <div className="mt-4 flex justify-between">
              <button
                onClick={() => handleDelete(review.id)}
                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-700"
              >
                Conferma Eliminazione
              </button>
              <button
                onClick={handleCancelDelete}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-500"
              >
                Annulla
              </button>
            </div>
          ) : (
            <button
              onClick={() => handleConfirmDelete(review.id)}
              className="text-red-500 hover:underline mt-4"
            >
              Elimina
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default DBReviews;
