const initialState = {
  contacts: [], // Stato iniziale con un array vuoto di contatti
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ALL_CONTACTS":
      return {
        ...state,
        contacts: action.contacts, // Imposta i contatti ricevuti nell'array contacts
      };
      case "REMOVE_CONTACT":
      return {
        ...state,
        contacts: state.contacts.filter((contact) => contact.id !== action.payload),
      };
    default:
      return state;
  }
};

export default contactReducer;
