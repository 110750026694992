import React from "react";
import { Logo } from "../assets";
import { NavLink } from "react-router-dom";
import SocialIcon from "./SocialIcon";

const Footer = () => {

  return (
    <footer className="w-full bg-white text-green py-10">
      <div className="mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-center">
        {/* Sezione Resta in Contatto */}
        <div>
          <h3 className="text-headingColor text-lg font-semibold mb-4">
            Resta In Contatto
          </h3>
          <p className="mb-3">
            Sede Struttura: B&B Piazza d'Armi
            <br /> Via Enrico Toti 2, Lamezia Terme (CZ)
          </p>
          <p className="mb-3">
            Email:{" "}
            <a
              href="mailto:info@bebpiazzadarmi.it"
              className="text-headingColor"
            >
              info@bebpiazzadarmi.it
            </a>
          </p>
          <p className="mb-3">
            Telefono:{" "}
            <a href="tel:+390968407921" className="text-headingColor">
              +390968407921
            </a>
          </p>
        </div>

        {/* Sezione Link Veloci */}
        <div>
          <h3 className="text-headingColor text-lg font-semibold mb-4">
            Link Veloci
          </h3>
          <ul>
            <li className="mb-3">
              <NavLink to="/" className="hover:text-headingColor">
                Home
              </NavLink>
            </li>
            <li className="mb-3">
              <NavLink to="/camere" className="hover:text-headingColor">
                Camere
              </NavLink>
            </li>
            <li className="mb-3">
              <NavLink to="/gallery" className="hover:text-headingColor">
                Gallery
              </NavLink>
            </li>
            <li className="mb-3">
              <NavLink to="/contatti" className="hover:text-headingColor">
                Contatti
              </NavLink>
            </li>
          </ul>
        </div>

        {/* Sezione Help Center */}
        <div>
          <h3 className="text-headingColor text-lg font-semibold mb-4">
            Help Center
          </h3>
          <ul>
            <li className="mb-3">
              <NavLink
                to="/termini-e-condizioni"
                className="hover:text-headingColor"
              >
                Termini e Condizioni
              </NavLink>
            </li>
            <li className="mb-3">
              <NavLink to="/cookie-policy" className="hover:text-headingColor">
                Cookie Policy
              </NavLink>
            </li>
            <li className="mb-3">
              <NavLink to="/privacy-policy" className="hover:text-headingColor">
                Privacy Policy
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      {/* Sezione Branding */}
      <div className="border-t mt-8 pt-8">
        
        <SocialIcon />
        <NavLink to={"/"} className="flex items-center justify-center gap-4">
          <img src={Logo} alt="logo" className="w-28" />
        </NavLink>

        {/* Copyright */}
        <div className="text-center mt-8 text-sm text-gray-400">
          <p>© Copyright 2024 B&B Piazza d'Armi - C.F. e P.IVA 03902020795</p>
          <p>Design by Promoty</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
