import React from 'react';
import {Link} from "react-router-dom"

const NotFound = () => {
  return (
    <div className="flex p-6 rounded-md items-center justify-center bg-gradient-to-r from-indigo-600 to-purple-600 text-white">
      <div className="text-center">
        <h1 className="text-6xl font-extrabold mb-4">404</h1>
        <p className="text-2xl mb-6">Pagina non trovata</p>
        <p className="text-lg mb-6">Siamo spiacenti, ma non possiamo trovare la pagina che stai cercando.</p>
        <Link
          to={"/"}
          className="inline-block py-2 px-6 bg-white text-indigo-600 rounded-full font-semibold hover:bg-indigo-600 hover:text-white transition duration-300"
        >
          Torna alla Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
