import { motion } from "framer-motion";
import React, { useState } from "react";
import { HiCurrencyEuro } from "../assets/icons";
import { jsPDF } from "jspdf"; // Importa jsPDF
import Logo from '../assets/img/logo.png';
import "jspdf-autotable"; // Assicurati che il percorso sia corretto

const BookingData = ({ index, data, admin }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const generatePdf = () => {
    const doc = new jsPDF();

    // Centra il logo
    const logo = Logo; // Assicurati che il percorso dell'immagine sia corretto
    const logoWidth = 50; // Larghezza del logo
    const logoHeight = 50; // Altezza del logo
    const pageWidth = doc.internal.pageSize.width; // Larghezza della pagina
    const logoX = (pageWidth - logoWidth) / 2; // Calcola la posizione orizzontale per centrare
    doc.addImage(logo, 'PNG', logoX, 50, logoWidth, logoHeight); // Aggiungi il logo

    // Aggiungi un titolo
    doc.setFontSize(18);
    doc.text('DETTAGLI PRENOTAZIONE', 80, 30);

    // Aggiungi il contenuto al PDF
    doc.setFontSize(12);

    // Crea tabella per maggior chiarezza
    const tableColumn = ["Dettaglio", "Informazione"];
    const tableRows = [
      ["Prenotazione N.", data.bookingId],
      ["Data Creazione", data.created],
      ["Totale", `€${data.total.toFixed(2)}`],
      ["Stato", data.status === "paid" ? "Pagato" : data.status],
      ["Nome Cliente", data.customer.name],
      ["Email Cliente", data.customer.email],
      ["Tel Cliente", data.customer.phone],
      ["Nome Stanza", data.roomName],
      ["Tipo Stanza", data.roomType],
      ["Data Inizio", data.startDate],
      ["Data Fine", data.endDate],
    ];

    // Riduci il margine tra il logo e la tabella
    doc.autoTable({
      startY: 120, // Abbiamo abbassato il margine, ora parte a Y = 120
      head: [tableColumn],
      body: tableRows,
      theme: 'grid',
      margin: { top: 10, left: 10, right: 10 }, // Puoi anche regolare altri margini qui
      styles: {
        cellWidth: 'auto',
        fontSize: 10,
      }
    });

    // Salva o apri il PDF
    doc.save("booking-details.pdf");
};


  return (
    <motion.div className="w-full flex flex-col items-start justify-start px-3 py-2 border border-gray-300 bg-white drop-shadow-md rounded-md gap-4">
      {/* Intestazione dell'accordione */}
      <div
        className="w-full flex justify-between items-center cursor-pointer"
        onClick={toggleAccordion}
      >
        <h1 className="text-xl text-headingColor font-semibold">
          Prenotazione N. #{data.bookingId}
        </h1>
        <p className="text-headingColor font-medium">
          {isAccordionOpen ? "Chiudi" : "Apri"}
        </p>
      </div>

      {/* Sezione accordione che si espande e si comprime */}
      {isAccordionOpen && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          transition={{ duration: 0.3 }}
          className="w-full flex flex-col gap-4"
        >
          <div>
            <h2 className="text-lg text-headingColor font-semibold">
              Dettagli Prenotazione
            </h2>
            <p className="text-base text-gray-800">Data: {data.created}</p>
            <p className="text-base text-gray-800">
              Totale: <HiCurrencyEuro className="inline text-emerald-600" />{" "}
              {data.total.toFixed(2)}
            </p>
            <p
              className={`mr-1 mt-2 text-sm text-white font-semibold bg-emerald-400 px-2 py-1 rounded-md inline-block`}
            >
              Stato: {data.status === "paid" ? "Pagato" : data.status}
            </p>
          </div>

          <div className="w-full">
            <h2 className="text-lg text-headingColor font-semibold">
              Informazioni Cliente
            </h2>
            <p className="text-base text-gray-800">
              Nome: {data.customer.name}
            </p>
            <p className="text-base text-gray-800">
              Email: {data.customer.email}
            </p>
            <p className="text-base text-gray-800">
              Tel: {data.customer.phone}
            </p>
          </div>

          <div className="w-full">
            <h2 className="text-lg text-headingColor font-semibold">
              Dettagli Camera
            </h2>
            <p className="text-base text-gray-800">Id Utente / Provenienza: <strong>{data.userId}</strong></p>

            <p className="text-base text-gray-800">Nome: {data.roomName}</p>
            <p className="text-base text-gray-800">Tipo: {data.roomType}</p>
            <p className="text-base text-gray-800">
              Data Inizio: {data.startDate}
            </p>
            <p className="text-base text-gray-800">
              Data Fine: {data.endDate}
            </p>
          </div>

          {admin && (
            <motion.button
              className="bg-emerald-400 text-white p-2 rounded-md"
              onClick={generatePdf}
            >
              Stampa Prenotazione
            </motion.button>
          )}
        </motion.div>
      )}
    </motion.div>
  );
};

export default BookingData;
