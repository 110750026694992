import React from "react";
import { facebook, whatsapp, instagram } from "../assets/index"; // Assicurati che i percorsi siano corretti

const SocialIcon = () => {
  return (
    <div className="flex justify-center">
      <div className="text-center">
        <p className="text-lg font-semibold">Seguici sui nostri Social</p>
        <div className="flex justify-center mt-4 space-x-6 mb-8">
          {/* Instagram */}
          <a
            href="https://www.instagram.com/bebpiazzadarmi?igsh=NW0wMGJkYmZmcjF6"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagram} alt="Instagram logo" className="w-10 h-10 transition-transform transform hover:scale-110" />
          </a>

          {/* Facebook */}
          <a
            href="https://www.facebook.com/profile.php?id=61569238514634"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebook} alt="Facebook logo" className="w-10 h-10 transition-transform transform hover:scale-110" />
          </a>

          {/* WhatsApp */}
          <a
            href="https://wa.me/+393336754126"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={whatsapp} alt="WhatsApp logo" className="w-10 h-10 transition-transform transform hover:scale-110" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SocialIcon;
