// Action per settare i contatti nel Redux store
export const setAllReviews = (reviews) => {
    return {
      type: "SET_ALL_REVIEWS",
      reviews,
    };
  };
  
  export const removeReviews = (id) => {
    return {
      type: "REMOVE_REVIEWS",
      payload: id, // Passa l'id del contatto da rimuovere
    };
  };