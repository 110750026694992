import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { sendReview, getAllReviews } from "../api";
import { setAllReviews } from "../context/actions/reviewAction";

const ReviewForm = () => {
  const [name, setName] = useState("");
  const [rating, setRating] = useState(1);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendReview(name, rating, message);
      const updatedReviews = await getAllReviews();
      dispatch(setAllReviews(updatedReviews));

      setName("");
      setRating(1);
      setMessage("");
    } catch (error) {
      console.error("Errore nell'invio della recensione:", error);
    }
  };

  const renderStarButtons = () => {
    return [1, 2, 3, 4, 5].map((num) => (
      <button
        key={num}
        type="button"
        className={`p-1 text-4xl transition duration-300 ${
          rating >= num ? "text-yellow-500" : "text-gray-300"
        } hover:text-yellow-400`}
        onClick={() => setRating(num)}
      >
        ★
      </button>
    ));
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-lg mx-auto p-6 bg-white rounded-xl shadow-lg">
      <h2 className="text-2xl text-headingColor font-semibold mb-4 text-center">Lascia la tua Recensione</h2>
      <p className="text-l text-secondary font-semibold mb-4 text-center">Per noi la vostra opinione é importate al fine di migliorare il servizio offerto</p>

      
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Il tuo nome"
        className="border-headingColor border w-full p-3 rounded-md mb-4 text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500"
        required
      />
      
      <div className="flex justify-center space-x-2 mb-4">
        {renderStarButtons()}
      </div>
      
      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Cosa ti è piaciuto della nostra struttura?"
        className="w-full p-3 rounded-md mb-4 border-headingColor border text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500"
        required
      ></textarea>

      <button
        type="submit"
        className="w-full bg-headingColor text-white py-2 rounded-md hover:bg-blue-600 transition duration-300"
      >
        Invia Recensione
      </button>
    </form>
  );
};

export default ReviewForm;
